'use strict';

angular
	.module('bringgApp')
	.directive(
		'optimizeAssignDrivers',
		function (Teams, Authentication, OPTIMIZATION_TYPES, ReactModals, MerchantConfigurations, Application) {
			return {
				restrict: 'E',
				replace: true,
				scope: {
					assignments: '=',
					optimizationType: '=',
					serviceAreasById: '=',
					dispatch: '=',
					selectedTasks: '='
				},
				templateUrl: 'scripts/features/optimize/steps/assign-drivers.html',
				link: function (scope) {
					scope.OPTIMIZATION_TYPES = OPTIMIZATION_TYPES;

					scope.isAnonymous = scope.optimizationType === OPTIMIZATION_TYPES.ANONYMOUS;

					scope.activeTeamIndex = 0;
					scope.selectTeam = function (idx) {
						scope.activeTeamIndex = idx;
					};
					scope.isFulfilled = function (team) {
						return scope.isAnonymous && _.every(team.user_types, 'fulfilled');
					};

					scope.handlePreviewClicked = function (optimizationResult) {
						var currentUser = Authentication.currentUser();
						var team = Teams.teamsByIdMap[optimizationResult.team_id];

						const routesPlannerEnable = MerchantConfigurations.applications.some(
							Application.isRouteOptimizer2
						);
						const teamSelectedTasks = scope.selectedTasks.filter(task => task.team_id === team.id);
						if (routesPlannerEnable) {
							ReactModals.openRoutesPlannerModal({
								isOpenFromDispatch: false,
								optimizationType: scope.optimizationType,
								teamId: team.id,
								selectedTasks: teamSelectedTasks,
								optimizationUuid: optimizationResult.request_uuid,
								isManualOptimization: false,
								optimizationResults: optimizationResult,
								onApplyChanges: userChanges => (optimizationResult.data = userChanges.data)
							});
						}
					};
				}
			};
		}
	);
