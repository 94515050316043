'use strict';

angular
	.module('bringgApp')
	.factory(
		'RoutesPlannerService',
		function (WayPointsService, TASK_DEFAULT_TYPES, ReactModals, optimizationTypesService) {
			var RoutesPlannerService = {};

			RoutesPlannerService.filterTasksForRoutesPlaner = function (tasks) {
				const filteredTasks = tasks.filter(task => {
					if (task.task_type_id === TASK_DEFAULT_TYPES.RETURN_TASK) {
						return false;
					}
					const taskDropOfWayPoint = WayPointsService.getDropOffWayPoint(task);
					if (!taskDropOfWayPoint) {
						return false;
					}
					return true;
				});

				return filteredTasks;
			};

			RoutesPlannerService.open = function (selectedItems, isPartialRouteWasSelected) {
				const teamId = selectedItems[0].team_id;
				selectedItems = RoutesPlannerService.filterTasksForRoutesPlaner(selectedItems);

				ReactModals.openRoutesPlannerModalManually({
					optimizationType: optimizationTypesService.getConfiguredOptimizationType(teamId),
					selectedTasks: selectedItems,
					isPartialRouteWasSelected
				});
			};

			return RoutesPlannerService;
		}
	);
