'use strict';

angular
	.module('bringgApp')
	.service(
		'GridColumnsService',
		function (
			$log,
			Authentication,
			$filter,
			AutoDispatchAnalytic,
			TranslationService,
			Teams,
			CUSTOM_ATTRIBUTES_CONSTS,
			SkillsService,
			SlickGridConfigService,
			Employee,
			CustomAttributesService
		) {
			var GridColumnsService = {};

			var emptyFilteringEnabled =
				Authentication.currentUser().feature_flags.empty_filtering ||
				Authentication.currentUser().feature_flags.empty_filters_planning_backend;

			var autoDispatchValueToKey = {
				1: 'AUTO_DISPATCH_STATE.TASK.IN_PROCESS',
				2: 'AUTO_DISPATCH_STATE.TASK.DISPATCHED',
				3: 'AUTO_DISPATCH_STATE.TASK.FAILED',
				4: 'AUTO_DISPATCH_STATE.TASK.NOT_APPLIED'
			};

			function checkDateRange(start, end, filterRange) {
				if (!start && !end) {
					return false;
				}

				let isInRange = true;

				if (start && filterRange[0]) {
					isInRange = isInRange && start >= filterRange[0];
				}

				if (end && filterRange[1]) {
					isInRange = isInRange && end <= filterRange[1];
				}

				return isInRange;
			}

			GridColumnsService.initGrid = function (scope) {
				scope.grid = scope.grid || {};
				scope.filterObject = scope.filterObject || { searchString: '' };

				var checkboxSelector = new Slick.CheckboxSelectColumn();
				scope.onSelection =
					scope.onSelection ||
					function () {
						if (!scope.$$phase) {
							scope.$apply();
						}
					};

				scope.selectedRows = [];
				scope.selectedItems = [];
				scope.getItemMetadata = GridColumnsService.getItemMetadata;

				return checkboxSelector;
			};

			GridColumnsService.getItemMetadata = function (metaDataProvider) {
				return function (row) {
					var item = this.getItem(row),
						metaData = metaDataProvider(row) || {};

					if (item.id) {
						metaData.cssClasses = 'taskid' + item.id;
					}

					return metaData;
				};
			};

			GridColumnsService.getDynamicFieldPosition = function (field, columns) {
				if (field.position === 'first') {
					return 2;
				} else if (field.position === 'last') {
					return columns.length - 1;
				} else if (field.position === 1 || _.isNaN(field.position)) {
					return 2;
				} else {
					return field.position;
				}
			};

			GridColumnsService.getFilterFuncForGrid = function (grid) {
				// filter searchable columns in visible columns
				var columns = _.filter(grid.getColumns(), function (column) {
					return !!column.getSearchValue;
				});

				return GridColumnsService.filterFuncByColumns(columns);
			};

			var UNASSIGNED_TASKS = 0;
			var ASSIGNED_TASKS = 1;

			var UNPLANNED_TASKS = 0;
			var PLANNED_TASKS = 1;

			const NOT_READY_FOR_PICKUP = 1;
			const READY_FOR_PICKUP = 2;

			const YES_OPTION_VALUE = 2;
			// Those keys should be the same as in node_modules/@bringg/types/types/filter_conditions.d.ts
			const FILTER_CONDITIONS = {
				EQUALS: 'EQUALS',
				NOT_EQUALS: 'NOT_EQUALS',
				IS_EMPTY: 'IS_EMPTY',
				IS_NOT_EMPTY: 'IS_NOT_EMPTY',
				GREATER_THAN: 'GREATER_THAN',
				GREATER_THAN_OR_EQUALS: 'GREATER_THAN_OR_EQUALS',
				LESS_THAN: 'LESS_THAN',
				LESS_THAN_OR_EQUALS: 'LESS_THAN_OR_EQUALS'
			};

			function getFilterByCustomAttributeColumnsResult(filtersFeed, item) {
				let innerResult = true;

				const customAttributeFilters = filtersFeed.addedFilters?.filter(filterKey =>
					filterKey.includes(CUSTOM_ATTRIBUTES_CONSTS.TASK_COLUMNS_PREFIX)
				);

				if (!customAttributeFilters?.length) {
					return innerResult;
				}

				const customAttributeColumnsById = new Map(
					CustomAttributesService.generateTaskColumns().map(c => [c.id, c])
				);

				customAttributeFilters.forEach(filterKey => {
					if (innerResult && filtersFeed[filterKey]) {
						const column = customAttributeColumnsById.get(filterKey);

						if (column) {
							const value = column.getSearchValue(item);

							switch (true) {
								// for CA with CustomAttributeDataType.String
								case _.isString(filtersFeed[filterKey]): {
									innerResult = value && String(value).includes(filtersFeed[filterKey]);
									return;
								}
								// for CA with CustomAttributeDataType.Boolean
								// filter value is number type `1` or `2`. Filter bar cannot work with `boolean` radio button, only number
								// `1` stands for `false` and `2` stands for `true`(YES_OPTION_VALUE)
								case _.isNumber(filtersFeed[filterKey]): {
									innerResult = value === Boolean(filtersFeed[filterKey] === YES_OPTION_VALUE);
									return;
								}
								// for CA with CustomAttributeDataType.Enum
								case _.isArray(filtersFeed[filterKey]): {
									innerResult = filtersFeed[filterKey].length
										? filtersFeed[filterKey].includes(value)
										: true;
									return;
								}
								// for CA with CustomAttributeDataType.Number
								// since number filter with conditions it is saved as object with FILTER_CONDITIONS(enum from service-types package)
								case _.isObject(filtersFeed[filterKey]): {
									let branchResult = true;
									const searchValue = Number(filtersFeed[filterKey]?.value);

									switch (filtersFeed[filterKey]?.condition) {
										case FILTER_CONDITIONS.EQUALS:
											branchResult = value === searchValue;
											break;

										case FILTER_CONDITIONS.NOT_EQUALS:
											branchResult = value !== searchValue;
											break;

										case FILTER_CONDITIONS.GREATER_THAN:
											branchResult = value > searchValue;
											break;

										case FILTER_CONDITIONS.GREATER_THAN_OR_EQUALS:
											branchResult = value >= searchValue;
											break;

										case FILTER_CONDITIONS.LESS_THAN:
											branchResult = value < searchValue;
											break;

										case FILTER_CONDITIONS.LESS_THAN_OR_EQUALS:
											branchResult = value <= searchValue;
											break;

										case FILTER_CONDITIONS.IS_EMPTY:
											branchResult = _.isNil(value);
											break;

										case FILTER_CONDITIONS.IS_NOT_EMPTY:
											branchResult = !_.isNil(value);
											break;

										default:
											break;
									}

									innerResult = branchResult;
									return;
								}
								default:
									break;
							}
						}
					}
				});

				return innerResult;
			}

			GridColumnsService.filterFuncByColumns = function (columns) {
				return function (item, args) {
					var result = true;
					if (!args || !item) {
						return result;
					}

					if (args.searchString) {
						var searchString = args.searchString.toString().toLowerCase().trim();
						result = _.some(columns, function (column) {
							var columnValue = (column.getSearchValue(item) || '').toString().toLowerCase();
							return columnValue.indexOf(searchString) >= 0;
						});
					}

					if (args.plannedTask === PLANNED_TASKS) {
						result = result && item.planning_done;
					} else if (args.plannedTask === UNPLANNED_TASKS) {
						result = result && !item.planning_done;
					}

					if (args.unscheduled) {
						result = result && item.virtual_task;
					}

					if (result && args.timeWindowStart) {
						var wp = _.last(item.way_points);

						if (wp?.no_earlier_than || wp?.no_later_than) {
							result = checkDateRange(wp.no_earlier_than, wp.no_later_than, args.dates);
						} else if (item.scheduled_at) {
							result = checkDateRange(item.scheduled_at, item.scheduled_at, args.dates);
						} else {
							result = false;
						}
					}

					if (result && args.selectedTeams) {
						var selectedTeams = _.flatten([args.selectedTeams]);

						if (!_.isEmpty(selectedTeams) || emptyFilteringEnabled) {
							const noTeam = selectedTeams.find(team => team < 0);

							if (noTeam && !item.team_ids[0]) {
								return true;
							}

							var selectedTeamsWithChildren = Teams.getTeamIdsWithChildIds(selectedTeams);
							var selectedTeamsItemIsIn = _.intersection(item.team_ids, selectedTeamsWithChildren);
							result = result && !_.isEmpty(selectedTeamsItemIsIn);
						}
					}

					if (result && args.selectedTags && !_.isEmpty(args.selectedTags)) {
						result = result && _.includes(args.selectedTags, item.tag_id);
					}

					if (result && args.firstCustomer) {
						result = _.first(item.way_points)
							?.customer.name?.toLowerCase()
							.includes(args.firstCustomer.toLowerCase());
					}

					if (result && args.lastCustomer) {
						result = _.last(item.way_points)
							?.customer.name?.toLowerCase()
							.includes(args.lastCustomer.toLowerCase());
					}

					if (result && args.firstAddress) {
						result =
							!_.isEmpty(_.first(item.way_points)?.address) &&
							_.first(item.way_points)?.address.toLowerCase().includes(args.firstAddress.toLowerCase());
					}

					if (result && args.lastAddress) {
						result =
							!_.isEmpty(_.last(item.way_points)?.address) &&
							_.last(item.way_points).address.toLowerCase().includes(args.lastAddress.toLowerCase());
					}

					if (result && args.firstCity) {
						result =
							!_.isEmpty(_.first(item.way_points)?.city) &&
							_.first(item.way_points).city.toLowerCase().includes(args.firstCity.toLowerCase());
					}

					if (result && args.lastCity) {
						result =
							!_.isEmpty(_.last(item.way_points)?.city) &&
							_.last(item.way_points).city.toLowerCase().includes(args.lastCity.toLowerCase());
					}

					if (result && args.rank) {
						result = result && _.includes(args.rank, item.rank);
					}

					if (result) {
						if (args.unassigned === ASSIGNED_TASKS) {
							result = !_.isNil(item.user_id);
						} else if (args.unassigned === UNASSIGNED_TASKS) {
							result = _.isNil(item.user_id);
						}
					}

					if (result && !_.isEmpty(args.autoDispatch)) {
						var key = AutoDispatchAnalytic.getTaskStatus(item.team_id, item);
						var arrayKeys = args.autoDispatch.map(function (value) {
							return autoDispatchValueToKey[value];
						});

						result = _.includes(arrayKeys, key);

						if (!key) {
							result = _.includes(arrayKeys, autoDispatchValueToKey[4]);
						}
					}

					if (result && !_.isEmpty(args.skills)) {
						result =
							!_.isEmpty(item.required_skills) &&
							args.skills.some(function (skill) {
								return item.required_skills.includes(SkillsService.skillsById[skill].name);
							});
					}

					if (result && !_.isEmpty(args.ranks)) {
						if (!item.rank) {
							result = false;
						} else {
							result = args.ranks.some(function (rank) {
								return item.rank === rank;
							});
						}
					}

					if (result && !_.isEmpty(args.fleets)) {
						result = args.fleets.some(function (fleet) {
							if (fleet === -1 && !item.fleet_id) {
								return true;
							}
							return item.fleet_id === fleet;
						});
					}

					if (result && (args.slaStartTime || args.slaEndTime)) {
						var wp = _.last(item.way_points);

						if (wp?.first_attempt_promise_no_earlier_than || wp?.first_attempt_promise_no_later_than) {
							result = checkDateRange(
								wp.first_attempt_promise_no_earlier_than,
								wp.first_attempt_promise_no_later_than,
								args.sla
							);
						} else {
							result = false;
						}
					}

					if (result && args.reservedUntilStartTime && args.reservedUntilEndTime) {
						result = checkDateRange(item.reserved_until, item.reserved_until, args.reservedUntil);
					}

					if (result && !_.isEmpty(args.servicePlans)) {
						if (!item.service_plan) {
							result = false;
						} else {
							result = args.servicePlans.some(function (servicePlan) {
								return item.service_plan.id === servicePlan;
							});
						}
					}

					if (result && args.readyForPickup) {
						if (args.readyForPickup == READY_FOR_PICKUP) {
							result =
								result &&
								!!item.preparation_ready_for_pickup_time_actual &&
								moment(item.preparation_ready_for_pickup_time_actual).isSameOrBefore(moment(), 's');
						} else if (args.readyForPickup == NOT_READY_FOR_PICKUP) {
							result =
								result &&
								(!item.preparation_ready_for_pickup_time_actual ||
									moment(item.preparation_ready_for_pickup_time_actual).isAfter(moment(), 's'));
						}
					}

					if (result) {
						result = getFilterByCustomAttributeColumnsResult(args, item);
					}

					if (result && !_.isEmpty(args.driversServiceArea)) {
						if (!item.user_id) {
							// when task isn't assigned to any drivers when filter `driversServiceArea` applied
							// we have to show unassigned task too(feature requirement)
							result = true;
						} else {
							const driver = Employee.getByIdFromStoredItems(item.user_id);
							if (driver?.service_area_id) {
								result = args.driversServiceArea.includes(driver.service_area_id);
							} else {
								result = false;
							}
						}
					}

					return result;
				};
			};

			/**
			 * update grid columns from settings
			 * @param gridColumns
			 */
			GridColumnsService.getConvertedColumnsFromMerchantConfigurations = function (gridColumns, columnsKey) {
				gridColumns = gridColumns || [];
				var updatedGridColumns = SlickGridConfigService.getColumnsFromMerchantConfigurations(columnsKey);

				updatedGridColumns = _.sortBy(updatedGridColumns, function (gridColumn) {
					return gridColumn.position;
				});

				updatedGridColumns.forEach(function (taskField) {
					var fieldExists = _.find(gridColumns, { id: taskField.id });
					if (taskField.active) {
						if (_.isUndefined(fieldExists)) {
							var position = GridColumnsService.getDynamicFieldPosition(taskField, gridColumns);
							var validColumn = true;

							var column = {
								id: taskField.id,
								name: taskField.translate
									? TranslationService.instant(taskField.title)
									: taskField.title,
								field: taskField.field || taskField.id,
								getSearchValue: taskField.getSearchValue,
								sortable: true,
								isFirstWayPoint: taskField.isFirstWayPoint,
								getValue: taskField.getValue,
								width: taskField.width,
								formatter: $filter('taskGridCustomItemRenderer')(
									taskField.getValue || taskField.field || taskField.id
								)
							};

							if (taskField.customSortField) {
								column.customSortField = taskField.customSortField;
							}

							// very rare case that we do not user filter at all
							if (taskField.noFilter) {
								delete column.formatter;
							}

							if (taskField.postDefaultFilter) {
								column.postDefaultFilter = taskField.postDefaultFilter;
							}

							if (taskField.filter) {
								try {
									column.filter = taskField.filter;
									column.formatter = $filter(taskField.filter)(
										taskField.filterField || taskField.field || taskField.id
									);
								} catch (error) {
									validColumn = false;
									$log.warn(
										'Received column from merchant configuration with filter that does not exist, ignoring column ' +
											column.name
									);
								}
							}

							if (validColumn) {
								gridColumns.splice(position, 0, column);
							}
						}
					} else {
						if (!_.isUndefined(fieldExists)) {
							var index = _.findIndex(gridColumns, fieldExists);
							gridColumns.splice(index, 1);
						}
					}
				});

				return gridColumns;
			};

			return GridColumnsService;
		}
	);
