'use strict';

angular
	.module('bringgApp')
	.controller(
		'OptimizeModalController',
		function (
			$scope,
			$uibModalInstance,
			Optimization,
			$translate,
			toastr,
			dispatch,
			selectedTasks,
			optimizeDrivers,
			automaticOptimization,
			tasksScheduledDate,
			preserveCurrentRuns,
			$timeout,
			$log,
			$q,
			UserTypes,
			CrewsService,
			ServiceAreasService,
			Teams,
			optimizationType,
			OPTIMIZATION_TYPES,
			InProcessOptimization,
			TimeManagerService,
			bringg_utils,
			Employees
		) {
			$scope.optimizationType = optimizationType;
			$scope.dispatch = dispatch;
			$scope.OPTIMIZATION_TYPES = OPTIMIZATION_TYPES;
			$scope.isNamedPDPorVRP = [
				OPTIMIZATION_TYPES.NAMED_PDP,
				OPTIMIZATION_TYPES.NAMED_VRP,
				OPTIMIZATION_TYPES.COMBINED
			].includes($scope.optimizationType);
			$scope.isAnonymous = $scope.optimizationType === OPTIMIZATION_TYPES.ANONYMOUS;
			$scope.isRouteBased = $scope.optimizationType === OPTIMIZATION_TYPES.ROUTE_BASED;
			$scope.isCrewBased = $scope.optimizationType === OPTIMIZATION_TYPES.CREW_BASED;
			$scope.step = 'one';
			$scope.selectedTasks = selectedTasks;
			$scope.automaticOptimization = automaticOptimization;
			$scope.tasksScheduledDate = tasksScheduledDate;
			$scope.preserveCurrentRuns = preserveCurrentRuns;
			$scope.drivers = optimizeDrivers;
			$scope.selectedDriversByTeam = [];
			$scope.loading = true;
			$scope.nextButtonTitle = $translate.instant('OPTIMIZE_TASKS.NEXT_BUTTON');
			$scope.result = {};
			$scope.optimizationDone = false;
			$scope.assignments = [];
			$scope.isAnyServiceArea = false;
			$scope.optimizationError = false;

			const RUN_OPTIMIZATION_STEP = 2;
			const TIMEOUT_ERROR_CODE = 24;

			var groupedDrivers = [];
			var teamsById, userTypesById;
			var completedTeamOptimizations = [];

			function getAssignedDrivers(userTypes) {
				var drivers = _.chain(userTypes).map('drivers').flatten().value();

				var selectedDrivers = _.filter(drivers, 'selected');

				return _.isEmpty(selectedDrivers) ? _.map(drivers, 'id') : _.map(selectedDrivers, 'id');
			}

			function getTeamsAssignedDrivers(teams) {
				return _.map(teams, function (team) {
					return {
						team_id: team.team_id,
						request_uuid: team.request_uuid,
						user_ids: getAssignedDrivers(team.user_types)
					};
				});
			}

			function populateTeamDrivesIntoType(userType, teamId) {
				userType.drivers = _.chain(groupedDrivers[userType.id])
					.filter(function (driver) {
						return _.includes(driver.team_ids, teamId);
					})
					.map(_.clone)
					.value();
				userType.title = userTypesById[userType.id].title;
			}

			function prepareAnonymousAssignment(optimizationResults) {
				_.each(optimizationResults, function (team) {
					team.name = teamsById[team.team_id].name;
					_.each(team.user_types, function (userType) {
						populateTeamDrivesIntoType(userType, team.team_id);
					});
				});
				$scope.assignments = optimizationResults;
			}

			function addCustomFieldsToRequest(request) {
				request.custom_period = $scope.result.custom_period;
				request.period = 5;

				var scheduled_date = $scope.preserveCurrentRuns
					? $scope.tasksScheduledDate
					: $scope.result.scheduled_date;

				request.preserve_current_runs = $scope.preserveCurrentRuns;
				request.scheduled_date = TimeManagerService.format(scheduled_date, 'YYYY-MM-DD');
			}

			function prepareNamedAssignment(optimizationResults) {
				_.each(optimizationResults, function (team) {
					team.name = teamsById[team.team_id].name;
				});
				$scope.assignments = optimizationResults;
			}

			function getTeamSelectedTasks(teamId) {
				return _.chain($scope.selectedTasks)
					.filter(function (task) {
						return _.includes(task.team_ids, teamId);
					})
					.map('id')
					.value();
			}

			function createNamedOptimizationRequest() {
				return _.map($scope.selectedDriversByTeam, function (team) {
					var resultTeam = {
						team_id: team.id,
						optimize_for: $scope.result.optimize_for
					};
					if (resultTeam.optimize_for === 'custom') {
						addCustomFieldsToRequest(resultTeam);
					}
					resultTeam.driver_ids = _.map(team.drivers, 'id');

					resultTeam.task_ids = getTeamSelectedTasks(team.id);
					return resultTeam;
				});
			}

			function createOptimizationRequest() {
				return _.chain($scope.userTypesByTeam)
					.filter(function (team) {
						return _.some(team.userTypes, function (userType) {
							return userType.quantity > 0;
						});
					})
					.map(function (team) {
						var resultTeam = {
							team_id: team.id,
							optimize_for: $scope.result.optimize_for
						};
						if (resultTeam.optimize_for === 'custom') {
							addCustomFieldsToRequest(resultTeam);
						}
						resultTeam.user_types = _.chain(team.userTypes)
							.filter('quantity')
							.map(function (userType) {
								if ($scope.isRouteBased) {
									delete userType.quantity;
								}

								return _.pick(userType, 'id', 'quantity');
							})
							.value();
						resultTeam.task_ids = getTeamSelectedTasks(team.id);
						return resultTeam;
					})
					.value();
			}

			function createCrewOptimizationRequest() {
				return _.chain($scope.plannedRoutesByTeam)
					.filter(function (team) {
						return _.some(team.plannedRoutes, function (plannedRoute) {
							return plannedRoute.selected;
						});
					})
					.map(function (team) {
						var resultTeam = {
							team_id: team.id,
							optimize_for: $scope.result.optimize_for,
							optimization_type: optimizationType
						};

						if (resultTeam.optimize_for === 'custom') {
							addCustomFieldsToRequest(resultTeam);
						}

						resultTeam.planned_route_ids = _.chain(team.plannedRoutes)
							.filter('selected')
							.map(function (plannedRoute) {
								return plannedRoute.id;
							})
							.value();

						resultTeam.task_ids = getTeamSelectedTasks(team.id);
						return resultTeam;
					})
					.value();
			}

			$scope.$on('optimization done', function (event, data) {
				completedTeamOptimizations.push(data);
				if (!data.error) {
					return;
				}
				const errors = JSON.parse(data.error);
				const timeoutError = _.find(errors, { error_code: TIMEOUT_ERROR_CODE });
				if (timeoutError) {
					$scope.optimizationError = true;
				}
			});

			var updateProgressBar = function (progress) {
				$timeout(function () {
					// prevent race when realtime send done before updates
					$scope.currentProgress = Math.round((progress.iteration / progress.totalIterations) * 100);
				});
			};

			var throttled = _.throttle(updateProgressBar, 50);

			$scope.$on('optimization progress', function (event, progress) {
				throttled(progress);
			});

			$scope.$on('all optimizations cancelled', function () {
				$scope.close();
			});

			$scope.$on('all optimizations done', function () {
				var filteredTeamOptimizations = _.filter(completedTeamOptimizations, 'team_id');
				if ($scope.isAnonymous) {
					prepareAnonymousAssignment(filteredTeamOptimizations);
				} else {
					prepareNamedAssignment(filteredTeamOptimizations);
				}
				$timeout(function () {
					$scope.optimizationDone = true;
					$scope.nextStep(4);
				}, 100);
			});

			/**
			 * start optimization process
			 */
			$scope.startOptimize = function () {
				var teamOptimizationRequests;
				if ($scope.isNamedPDPorVRP) {
					teamOptimizationRequests = createNamedOptimizationRequest();
				} else if ($scope.isCrewBased) {
					teamOptimizationRequests = createCrewOptimizationRequest();
				} else {
					teamOptimizationRequests = createOptimizationRequest();
				}

				$scope.currentProgress = 0;
				$scope.totalTeamsToOptimize = teamOptimizationRequests.length;
				completedTeamOptimizations = [];

				_.each(teamOptimizationRequests, function (teamOptimizationRequest) {
					teamOptimizationRequest.request_uuid = bringg_utils.guid();
					InProcessOptimization.pendingOptimizationUUIDs.push(teamOptimizationRequest.request_uuid);

					Optimization.optimize(teamOptimizationRequest)
						.then(function (result) {
							if (result.success) {
								$log.info('Optimizing started we will notify you when the proccess is done');
								InProcessOptimization.onNewOptimizationRequest(teamOptimizationRequest.request_uuid);
							} else {
								InProcessOptimization.cancelPolling(teamOptimizationRequest.request_uuid);
								toastr.error('Could not start at this time.');
								$log.error('Optimization failed', result);
							}
						})
						.catch(function (err) {
							toastr.error($translate.instant('OPTIMIZE_TASKS.ERROR_OCCURRED_NETWORK_MISSING'));
							$log.error('Optimization failed with error', err);
							InProcessOptimization.cancelPolling(teamOptimizationRequest.request_uuid);
						});
				});
			};

			$scope.steps = [
				{
					key: 'selectRoles',
					title: $translate.instant('OPTIMIZE_TASKS.SELECT_ROLES_TITLE'),
					backButton: false,
					canGoNext: function () {
						if ($scope.isCrewBased) {
							return _.some($scope.plannedRoutesByTeam, function (team) {
								return _.some(team.plannedRoutes, 'selected');
							});
						} else if (($scope.isAnonymous || $scope.isRouteBased) && $scope.userTypesByTeam) {
							return _.some($scope.userTypesByTeam, function (team) {
								return _.some(team.userTypes, 'quantity');
							});
						} else if ($scope.isNamedPDPorVRP && $scope.selectedDriversByTeam.length) {
							return true;
						}

						return false;
					}
				},
				{
					key: 'selectDeliveryTime',
					title: $translate.instant('OPTIMIZE_TASKS.SELECT_DELIVERY_TIME_TITLE'),
					backButton: true,
					canGoNext: function () {
						if ($scope.result.optimize_for === 'custom') {
							if (!$scope.result.scheduled_date) {
								return false;
							}

							if (
								!$scope.result.ui_period_times ||
								!$scope.result.ui_period_times.start_period ||
								!$scope.result.ui_period_times.end_period
							) {
								return false;
							}

							if ($scope.result.ui_period_times.start_period > $scope.result.ui_period_times.end_period) {
								return false;
							}
						}
						return true;
					}
				},
				{
					key: 'summary',
					backButton: true,
					customNextTitle: $translate.instant('OPTIMIZE_TASKS.OPTIMIZE'),
					canGoNext: _.constant(true)
				},
				{
					key: 'optimize',
					backButton: false,
					onMove: $scope.startOptimize,
					customNextTitle: $translate.instant('OPTIMIZE_TASKS.OPTIMIZING'),
					canGoNext: _.constant(false)
				},
				{
					key: 'assign',
					backButton: false,
					title: $translate.instant('OPTIMIZE_TASKS.SELECT_DRIVERS_TITLE'),
					customNextTitle: $translate.instant('OPTIMIZE_TASKS.ASSIGN_DRIVERS'),
					canGoNext: _.constant(true)
				}
			];

			$scope.currentStepIndex = 0;
			$scope.currentStep = $scope.steps[$scope.currentStepIndex];

			$scope.nextStep = function (stepIndex) {
				if (!_.isUndefined(stepIndex)) {
					$scope.currentStepIndex = stepIndex;
				} else {
					$scope.currentStepIndex++;
				}
				$scope.currentStep = $scope.steps[$scope.currentStepIndex];
				if ($scope.currentStep.onMove) {
					$scope.currentStep.onMove();
				}
			};

			$scope.backStep = function () {
				$scope.currentStepIndex--;
				$scope.currentStep = $scope.steps[$scope.currentStepIndex];
			};

			$scope.cancel = function () {
				if ($scope.steps[$scope.currentStepIndex].key !== 'optimize') {
					$scope.close();
					return;
				}

				return InProcessOptimization.cancelAllOptimizations();
			};

			$scope.rerunOptimization = function () {
				$scope.optimizationError = false;
				$scope.currentStepIndex = RUN_OPTIMIZATION_STEP;
				$scope.nextStep();
			};

			$scope.close = function () {
				$uibModalInstance.close();
				$scope.optimizationError = false;
			};

			$scope.done = function () {
				var assignRequest = { teams: [], origin: 'route_optimization' };
				if ($scope.isAnonymous) {
					assignRequest.teams = getTeamsAssignedDrivers($scope.assignments);
					Optimization.optimizeAssignUsersByUserTypes(assignRequest, function () {
						$log.info('Optimization: assignment of drivers completed');
					});
				} else {
					assignRequest.teams = _.map($scope.assignments, function (team) {
						return { team_id: team.team_id, request_uuid: team.request_uuid };
					});

					if ($scope.isRouteBased) {
						Optimization.createRunsByRoutes(assignRequest, function () {
							$log.info('Optimization: creating runs by routes completed');
						});
					} else {
						Optimization.optimizeAssignUsersByIds(assignRequest, function () {
							$log.info('Optimization: assignment of drivers completed');
						});
					}
				}

				$scope.close();
			};

			function initTeamData(teams, userTypes, teamId) {
				var team = _.pick(_.find(teams, { id: teamId }), ['id', 'name']);
				var teamUserTypes = _.chain(userTypes).map(_.clone).value();
				bringg_utils.naturalSortByKey(teamUserTypes, 'title');
				_.defaults(team, { userTypes: teamUserTypes });
				return team;
			}

			function initTeamsData(teams) {
				var teamIds = _.chain($scope.selectedTasks).map('team_ids').flatten().uniq().value();
				teamsById = _.keyBy(teams, 'id');
				var userTypes = _.chain(UserTypes.userTypes.user_types)
					.reject(_.matches({ id: 0 }))
					.map(function (userType) {
						return _.defaults(_.pick(userType, 'id', 'title', 'cost_per_task', 'cost_per_shift'), {
							quantity: 0
						});
					})
					.value();

				userTypesById = _.keyBy(userTypes, 'id');

				$scope.userTypesByTeam = _.map(teamIds, function (teamId) {
					return initTeamData(teams, userTypes, teamId);
				});
				bringg_utils.naturalSortByKey($scope.userTypesByTeam, 'name');
				$scope.isAnyServiceArea = _.some($scope.drivers, function (driver) {
					return _.intersection(teamIds, driver.team_ids).length > 0 && !!driver.service_area_id;
				});
			}

			function initPlannedRoutes(teams) {
				var teamIds = _.chain($scope.selectedTasks).map('team_ids').flatten().uniq().value();
				$scope.plannedRoutesByTeam = [];
				var promises = [];

				teamIds.forEach(function (teamId) {
					promises.push(CrewsService.getAllByTeam(teamId));
				});

				$q.all(promises).then(function (results) {
					results.forEach(function (crews) {
						if (!_.isEmpty(crews)) {
							// Create new object with team fields and all planned routes
							var team = _.pick(_.find(teams, { id: crews[0].team_id }), ['id', 'name']);
							var teamPlannedRoutes = _.extend(team, {
								plannedRoutes: _.map(crews, getPlannedRouteFields)
							});

							$scope.plannedRoutesByTeam.push(teamPlannedRoutes);
						}
					});

					$scope.loading = false;
				});
			}

			function initServiceAreasById() {
				var teamIds = _.chain($scope.selectedTasks).map('team_ids').flatten().uniq().value();
				ServiceAreasService.getServiceAreaById(teamIds).then(function (serviceAreaById) {
					$scope.serviceAreasById = serviceAreaById;
				});
			}

			function getPlannedRouteFields(crew) {
				var drivers = crew.primary_driver ? [crew.primary_driver] : [];
				drivers = drivers.concat(crew.drivers || []);
				var driversNames = drivers
					.map(function (driver) {
						return formatDriverName(driver.name);
					})
					.join(', ');

				return _.extend(crew.planned_route, {
					drivers_names: driversNames,
					user_id: crew.primary_driver && crew.primary_driver.id
				});
			}

			function initDrivers() {
				groupedDrivers = _.chain($scope.drivers)
					.filter('id')
					.map(function (driver) {
						return _.pick(driver, ['name', 'id', 'user_type_id', 'team_ids']);
					})
					.filter('user_type_id')
					.groupBy('user_type_id')
					.value();
			}

			function formatDriverName(driverName) {
				if (!driverName) {
					return '';
				}

				var nameParts = driverName.split(' ');
				var firstName = _.first(nameParts);
				var otherInitials = _.chain(nameParts)
					.drop()
					.map(function (word) {
						if (_.isEmpty(word)) return null;
						return _.first(word).toUpperCase();
					})
					.compact()
					.value()
					.join(' ');

				return firstName + ' ' + otherInitials;
			}

			var init = function () {
				var promises = [Teams.all(), Employees.drivers()];

				$q.all(promises).then(function (results) {
					initTeamsData(results[0]);
					initDrivers();

					initServiceAreasById();

					if ($scope.isCrewBased) {
						initPlannedRoutes(results[0]);
					} else {
						$scope.loading = false;
					}
				});
			};

			$scope.showRoutesSteps = function () {
				return $scope.currentStepIndex < 4 || $scope.isRouteBased || $scope.isCrewBased;
			};

			init();
		}
	);
