'use strict';
angular
	.module('bringgApp')
	.service(
		'TasksTableColumns',
		function (
			CustomAttributesService,
			MerchantConfigurations,
			MaxLoadCalculationIndicator,
			$injector,
			optimizationTypesService,
			OPTIMIZATION_TYPES,
			CUSTOM_ATTRIBUTES_CONSTS,
			Authentication,
			PresetViewsService,
			$q
		) {
			const _this = this;
			_this.getTasksColumns = getTasksColumns;
			_this.getKdsTaskColumns = getKdsTaskColumns;
			_this.getRoutesPlannerColumns = getRoutesPlannerColumns;
			_this.getTasksColumnsByAppConfig = getTasksColumnsByAppConfig;
			_this.getTasksColumnsByMerchantConfiguration = getTasksColumnsByMerchantConfiguration;
			_this.columnsConfigReady = columnsConfigReady;

			function getTasksColumns(columnsKey) {
				return _.union(
					getHasToLeave(),
					getTasksMandatoryColumns(),
					getTasksDispatchGridColumns(columnsKey),
					getTasksMapColumns(),
					getTasksGroupedColumns(),
					getTasksColumnsByAppConfig(),
					getKdsTaskColumns(),
					getTasksColumnsByMerchantConfiguration(),
					getTaskPromiseTimes(),
					getRoutesPlannerColumns(),
					getExtras()
				)
					.filter(function (columnKey) {
						// Remove custom-attributes related columns, extras represent them all on data layer
						return (
							typeof columnKey === 'string' &&
							!columnKey.startsWith(CUSTOM_ATTRIBUTES_CONSTS.TASK_COLUMNS_PREFIX)
						);
					})
					.join(',');
			}

			function columnsConfigReady() {
				return Authentication.featureFlags().then(() => {
					const configPromisesToWait = [];
					if (PresetViewsService.getPresetViewsEnabled()) {
						configPromisesToWait.push(PresetViewsService.getPresetsReadyPromise());
					}

					return $q.all(configPromisesToWait);
				});
			}

			function getHasToLeave() {
				return ['first_way_point_has_to_leave_by'];
			}

			function getExtras() {
				return CustomAttributesService.attributesEnabled ? ['extras'] : [];
			}

			function getKdsTaskColumns() {
				var result = [];
				if (MerchantConfigurations.enable_kds === true) {
					result = result.concat([
						'preparation_status',
						'preparation_start_time_planned',
						'preparation_end_time_planned',
						'preparation_ready_for_pickup_time_planned',
						'title',
						'total_price',
						'payment_method',
						'preparation_external_id',
						'task_payment_histories.all.tax_payer_external_id',
						'task_payment_histories.all.tax_payer_name',
						'way_points.all.ui_data'
					]);
				}

				if (!_.isEmpty(MerchantConfigurations.preparation_flow_ui_config)) {
					var showTaskInventory = _.some(
						MerchantConfigurations.preparation_flow_ui_config.way_points_fields,
						{
							field: 'taskInventory'
						}
					);
					if (showTaskInventory) {
						result = result.concat([
							'task_inventories.all.id',
							'task_inventories.all.way_point_id',
							'task_inventories.all.name',
							'task_inventories.all.note',
							'task_inventories.all.quantity',
							'task_inventories.all.original_quantity',
							'task_inventories.all.price',
							'task_inventories.all.parent_task_inventory_id'
						]);
					}
				}

				return result;
			}

			function getTasksMandatoryColumns() {
				var result = [
					'active_way_point_id',
					'status',
					'id',
					'run_id',
					'way_points.all.position',
					'group_leader_id',
					'group_uuid',
					'priority',
					'late',
					'customer_id',
					'way_points.all.customer_id',
					'way_points.all.id',
					'way_points.all.no_later_than',
					'way_points.all.phone',
					'payment_type_name',
					'task_inventories.all.id',
					'task_inventories.all.weight',
					'task_inventories.all.way_point_id',
					'task_inventories.all.pending',
					'task_inventories.all.inventory',
					'task_inventories.all.inventory_id',
					'task_inventories.all.original_quantity',
					'task_inventories.all.handling_units',
					'ready_to_execute',
					'virtual_task',
					'done_planning',
					'task_type_id',
					'rogue_reasons',
					'invalidated',
					'automatically_assigned',
					'total_weight',
					'aggregations',
					'required_skills',
					'way_points.all.no_earlier_than',
					'vehicle_id',
					'service_plan',
					'preparation_ready_for_pickup_time_actual',
					'way_points.all.borough',
					'way_points.all.location_name',
					'way_points.all.company_name'
				];

				if (MaxLoadCalculationIndicator.shouldCalcLoadRecursively()) {
					result.push('task_inventories.all.parent_task_inventory_id');
				}

				(MerchantConfigurations.unique_inventory_fields || []).forEach(function (field) {
					result.push('task_inventories.all.' + field);
				});

				return result;
			}

			function getTasksDispatchGridColumns(columnsKey) {
				var slickGridConfigService = $injector.get('SlickGridConfigService');

				return _.chain(slickGridConfigService.getColumnsFromMerchantConfigurations(columnsKey))
					.filter('active')
					.map(function (column) {
						return column.requiredFields || column.field || column.id;
					})
					.flatten()
					.filter()
					.uniq()
					.valueOf();
			}

			function getTasksMapColumns() {
				return [
					'lat',
					'lng',
					'way_points.all.lat',
					'way_points.all.lng',
					'way_points.all.address',
					'way_points.all.city',
					'way_points.all.state',
					'way_points.all.street',
					'way_points.all.house_number',
					'way_points.all.zipcode',
					'way_points.all.scheduled_at',
					'team_ids',
					'tag_id',
					'way_points.all.customer'
				];
			}

			function getTasksGroupedColumns() {
				return ['late', 'way_points.all.eta', 'route_name', 'dispatcher_id'];
			}

			function getTaskPromiseTimes() {
				return [
					'way_points.all.first_attempt_promise_no_earlier_than',
					'way_points.all.first_attempt_promise_no_later_than'
				];
			}

			function getTasksColumnsByAppConfig() {
				var optimizationType = optimizationTypesService.getConfiguredOptimizationType();
				var result = [];

				if ([OPTIMIZATION_TYPES.NAMED_PDP, OPTIMIZATION_TYPES.COMBINED].includes(optimizationType)) {
					result.push('linked_task_id');
				}

				return result;
			}

			function getTasksColumnsByMerchantConfiguration() {
				var result = [];

				if (
					MerchantConfigurations.enable_task_acknowledge_flow &&
					!_.isEmpty(MerchantConfigurations.acknowledge_flow_ui_config)
				) {
					var showTaskInventory = _.some(
						MerchantConfigurations.acknowledge_flow_ui_config.way_points_fields,
						{
							field: 'taskInventory'
						}
					);
					if (showTaskInventory) {
						result = result.concat([
							'task_inventories.all.id',
							'task_inventories.all.way_point_id',
							'task_inventories.all.name',
							'task_inventories.all.note',
							'task_inventories.all.quantity',
							'task_inventories.all.original_quantity',
							'task_inventories.all.price',
							'task_inventories.all.parent_task_inventory_id'
						]);
					}
					var showTaskNotes = _.some(MerchantConfigurations.acknowledge_flow_ui_config.way_points_fields, {
						field: 'taskNote'
					});
					if (showTaskNotes) {
						result = result.concat([
							'task_notes.all.id',
							'task_notes.all.note',
							'task_notes.all.way_point_id',
							'task_notes.all.type'
						]);
					}
				}

				if (MerchantConfigurations.enable_fleets) {
					result = result.concat(['fleet_id', 'fleet_delivery_external_id']);
				}

				if (MerchantConfigurations.enable_task_price) {
					result = result.concat(['total_price']);
				}

				if (MerchantConfigurations.enable_vehicles && MerchantConfigurations.sort_driver_assignment_by_skills) {
					result = result.concat(['required_skills']);
				}

				return result;
			}

			function getRoutesPlannerColumns() {
				return [
					'user_id',
					'run_id',
					'external_id',
					'customer_name',
					'total_weight',
					'required_skills',
					'task_type_id',
					'active_way_point_id',
					'way_points.all.id',
					'way_points.all.etl',
					'way_points.all.etos',
					'way_points.all.no_later_than',
					'way_points.all.no_earlier_than',
					'way_points.all.pickup_dropoff_option',
					'linked_task_id'
				];
			}
		}
	);
