'use strict';
/*global _:false*/

angular
	.module('bringgApp')
	.value('HTTP_STATUS_CODE_FORBIDDEN', 403)
	.factory(
		'securityInterceptor',
		function ($q, toastr, autobind, $location, WEB_API_URL, $rootScope, $timeout, HTTP_STATUS_CODE_FORBIDDEN) {
			var SECURITY_ERRORS = [100401, 100403];
			var ANGULAR_SECURITY_ERRORS = [401, HTTP_STATUS_CODE_FORBIDDEN];
			var MAX_ERRORS = 5;
			var TTL = 15000;

			var interceptor = autobind({
				_securityErrors: [],
				_securityErrorsCount: function () {
					var now = Date.now();
					_.remove(this._securityErrors, function (item) {
						return now - item > TTL;
					});
					return this._securityErrors.length;
				},
				response: function (response) {
					return response;
				},
				responseError: function (rejection) {
					if (_.includes(ANGULAR_SECURITY_ERRORS, rejection.status)) {
						this._securityErrorHandler(rejection.config.url, rejection.data.message);
						return $q.reject(rejection);
					}
					return $q.reject(rejection);
				},
				_securityErrorHandler: function (url, message) {
					if (message) {
						toastr.error(message);
					}
					if (url.startsWith(WEB_API_URL)) {
						//we will logoff user in case
						this._securityErrors.push(Date.now());
						if (this._securityErrorsCount() > MAX_ERRORS) {
							this._securityErrors.length = 0;
							//force navigation to root - if
							toastr.error('User session expired');
							$timeout($location.path('/logout'));
						}
					}
				},
				_onHttpError: function (event, errorInfo) {
					if (_.includes(SECURITY_ERRORS, errorInfo.reason.code)) {
						this._securityErrorHandler(errorInfo.data.response.config.url, null);
					}
				}
			});

			$rootScope.$on('http failure', interceptor._onHttpError);

			return interceptor;
		}
	);

angular.module('bringgApp').config(function ($httpProvider) {
	$httpProvider.interceptors.push('securityInterceptor');
});
