'use strict';

angular
	.module('bringgApp.directives')
	.directive(
		'tasksTable',
		function (
			$window,
			MerchantConfigurations,
			Application,
			$q,
			Task,
			Tasks,
			TasksLoader,
			Employees,
			Authentication,
			EmployeesTasks,
			toastr,
			PrintTasksService,
			PrinterService,
			TranslationService,
			$interval,
			$translate,
			$timeout,
			$rootScope,
			$log,
			GridColumnsService,
			DEFAULT_RT_THROTTLE,
			RunsService,
			AutoDispatchAnalytic,
			VehiclesService,
			VehicleTypesService,
			RouteAssigner,
			CrossApplicationService,
			CROSS_APP_ACTIONS,
			TasksData,
			SlickGridConfigService,
			GRID_DATA,
			ManagedAttributesService,
			CollapsedLinkedTasksService
		) {
			return {
				templateUrl: 'scripts/directives/tasks/task-table.html',
				scope: {
					groupByValues: '=?',
					grid: '=?',
					tasks: '=?',
					gridId: '@?',
					filterObject: '=?',
					selectedItems: '=?',
					selectedRows: '=?',
					sortFields: '=?',
					onSelection: '=?',
					selectActiveRow: '=?',
					filteredItems: '=?',
					onInited: '=?',
					columnsKey: '@',
					plannedRoutes: '=',
					userTypes: '=',
					vehicleTypeByVehicleId: '=',
					isClusterModeEnabled: '=',
					doNotMultipleWeightByQuantity: '=',
					dynamicCapacityMode: '=',
					groupingType: '=?'
				},
				link: function (scope, element, attrs) {
					var initGridColumns = function () {
						var checkboxSelector = GridColumnsService.initGrid(scope);
						var columns = GridColumnsService.getConvertedColumnsFromMerchantConfigurations(
							null,
							scope.columnsKey
						);
						if (!_.isUndefined(attrs.checkbox)) {
							columns = [checkboxSelector.getColumnDefinition()].concat(columns);
						}
						if (_.find(columns, { id: 'way_points.first.time_until_scheduled' })) {
							scope.refreshInterval =
								scope.refreshInterval ||
								$interval(function () {
									refreshData();
								}, 60000);
						}
						scope.gridColumns = columns;
					};

					/**
					 * return new clone of employees
					 * @param employees
					 */
					var disabledEmployeesByConfigurations = function (employees) {
						var employeesClone = angular.copy(employees);

						_.each(employeesClone, function (employee) {
							if (!employee.id) {
								return;
							}

							if (
								(!MerchantConfigurations.allow_offshift_drivers_to_be_assigned &&
									!employee.active_shift_id) ||
								employee.is_on_break
							) {
								employee.disabled = true;
							}
						});

						return employeesClone;
					};

					/**
					 * this handles the ui select part - need to replace this all component
					 * @type {Object}
					 */
					var w = angular.element($window);

					function assignDriver(data) {
						let tasks = scope.tasks;
						if (CollapsedLinkedTasksService.isCollapseLinkedTaskEnabled()) {
							if (data.sourceTasks) {
								data.sourceTasks = CollapsedLinkedTasksService.extractTasksFromCollapsedTasks(
									data.sourceTasks
								);
							}
							if (tasks) {
								tasks = CollapsedLinkedTasksService.extractTasksFromCollapsedTasks(tasks);
							}
						}

						RouteAssigner.assignDriver(
							data,
							scope.handleListUpdate,
							{
								tasks: tasks,
								sourceRun: RunsService.getFromStore(data.runId),
								sourceTasks: data.sourceTasks,
								plannedRoutes: scope.plannedRoutes,
								userTypes: scope.userTypes,
								employees: scope.employees,
								vehicleTypeByVehicleId: scope.vehicleTypeByVehicleId
							},
							{
								isClusterModeEnabled: scope.isClusterModeEnabled,
								doNotMultipleWeightByQuantity: scope.doNotMultipleWeightByQuantity,
								dynamicCapacityMode: scope.dynamicCapacityMode,
								readyToExecute: true
							}
						);
					}

					w.on('handleAssignRunToDriver', function (event, data) {
						assignDriver(data);
					});

					w.on('handleAssignRunToVehicle', function (event, data) {
						RouteAssigner.assignVehicle(data, scope.tasks, scope.handleListUpdate);
					});

					w.on('handleAssignRunToTrailer', function (event, data) {
						RouteAssigner.assignTrailer(data, scope.tasks, scope.handleListUpdate);
					});

					scope.addVehicleTypeTitleToEmployee = function (employee) {
						if (employee.vehicle_id) {
							return VehiclesService.getVehicleTypeByVehicleId(employee.vehicle_id).then(
								function (vehicleType) {
									employee.vehicle_type_title = vehicleType?.title;
									return employee;
								},
								function (err) {
									console.error(err);
									return Promise.resolve(employee);
								}
							);
						}

						return Promise.resolve(employee);
					};

					scope.addVehicleDetailsToEmployee = function (employee) {
						if (employee.vehicle_id) {
							return VehiclesService.getVehicleDetailsByVehicleId(employee.vehicle_id).then(
								function (vehicle) {
									employee.vehicle = vehicle;
									employee.vehicle_type_title = vehicle?.type?.title;

									return employee;
								},
								function (err) {
									console.error(err);
									return Promise.resolve(employee);
								}
							);
						}

						return Promise.resolve(employee);
					};

					scope.normalizeEmployees = function (employees, taskId) {
						var floatingInventoryApp = _.find(MerchantConfigurations.applications, {
							uuid: Application.APPLICATIONS.FloatingInventoryApp
						});

						var normalizeEmployeesPromise = floatingInventoryApp
							? Tasks.getDriversWithAvailableInventories(employees, taskId)
							: Promise.resolve(employees);

						if (MerchantConfigurations.enable_vehicles) {
							return normalizeEmployeesPromise
								.then(function () {
									return Promise.all([VehiclesService.getAll(), VehicleTypesService.getAll()]);
								})
								.then(function () {
									var showVehicleDetails =
										Authentication.currentUser().feature_flags
											?.show_vehicle_name_in_driver_assignment_dropdown;

									return Promise.all(
										_.map(
											employees,
											showVehicleDetails
												? scope.addVehicleDetailsToEmployee
												: scope.addVehicleTypeTitleToEmployee
										)
									);
								});
						}

						return normalizeEmployeesPromise;
					};

					scope.sortOnShiftEmployeesByMatchingSkills = function (employees, task) {
						var result = _.partition(employees, function (employee) {
							return Boolean(employee.active_shift_id);
						});

						var onShiftEmployees = _.first(result);
						var offShiftEmployees = _.last(result);

						var qualifiedOnShiftEmployees = _.sortBy(onShiftEmployees, function (onShiftEmployee) {
							return -1 * _.intersection(task.required_skills, onShiftEmployee.skills).length;
						});
						return qualifiedOnShiftEmployees.concat(offShiftEmployees);
					};

					w.on('handleTaskGridDriverFilter', function (event, row) {
						$log.info('handleTaskGridDriverFilter called');

						if (!Authentication.currentUser().has_access('assign_driver_to_task')) {
							$log.info('handleTaskGridDriverFilter user has no access to assign tasks');
							return;
						}

						var taskId = row;
						var task = TasksData.get(taskId);

						if (RouteAssigner.canOpenPlannedRunsUserAssigner()) {
							return assignDriver({ sourceTasks: [task] });
						}

						var employees = EmployeesTasks.allEmployeesWithUnassigned(scope.employees, task.team_ids, true);
						employees = disabledEmployeesByConfigurations(employees);

						scope.normalizeEmployees(employees, taskId).then(function (normalizedEmployees) {
							$('#list_task_' + task.id + '_employee').hide();
							var existingSelection = $('#s2id_list_task_' + task.id + '_employee_list');
							var employees = normalizedEmployees;

							if (MerchantConfigurations.sort_by_skills_driver_assignment) {
								employees = scope.sortOnShiftEmployeesByMatchingSkills(employees, task);
							}

							if (existingSelection.length !== 0) {
								$('#list_task_' + task.id + '_employee_list')
									.show()
									.select2({
										data: { results: employees, text: 'name' },
										formatResult: function (employee) {
											return EmployeesTasks.formatEmployeeSelection(employee, task.team_ids);
										},
										formatSelection: function (employee) {
											return EmployeesTasks.formatEmployeeSelection(employee, task.team_ids);
										},
										dropdownAutoWidth: true,
										sortResults: EmployeesTasks.optimizedEmployeeListForTask
									})
									.select2('open');
								return;
							}

							$('#list_task_' + task.id + '_employee_list')
								.show()
								.select2({
									data: { results: employees, text: 'name' },
									formatResult: function (employee) {
										return EmployeesTasks.formatEmployeeSelection(employee, task.team_ids);
									},
									formatSelection: function (employee) {
										return EmployeesTasks.formatEmployeeSelection(employee, task.team_ids);
									},
									dropdownAutoWidth: true,
									//        query: function(query){ query.callback(); }
									sortResults: EmployeesTasks.optimizedEmployeeListForTask
								})
								.on('change', function (e) {
									closeSelectEmployee(task);
									scope.assignEmployee(task, e.val).then(function () {
										$(this).select2('refresh');
									});
								})
								.on('select2-close', function () {
									closeSelectEmployee(task);
								})
								.select2('open');
						});
					});

					let subscriptions = [];

					// clearing binding to prevent multi calls
					scope.$on('$destroy', function () {
						w.off('handleTaskGridDriverFilter');
						w.off('handleTaskGridPrintReceiptFilter');
						w.off('handleTaskGridPrintLabelFilter');
						w.off('handleAssignRunToDriver');
						w.off('handleAssignRunToVehicle');
						w.off('handleAssignRunToTrailer');
						CrossApplicationService.off(CROSS_APP_ACTIONS.PRESET_VIEWS_UPDATED, initGridColumns);

						$('[id^=s2id_list_task_]').remove();
						$('[id^=list_task_]').select2('destroy').remove();
						$('.select2-hidden-accessible').remove();
						$('.select2-drop').remove();
						$interval.cancel(scope.refreshInterval);

						subscriptions.forEach(function (cleanup) {
							cleanup();
						});
						subscriptions = [];
					});

					function closeSelectEmployee(task) {
						$log.info('closeSelectEmployee called');

						$('#s2id_list_task_' + task.id + '_employee_list').hide();
						$('#list_task_' + task.id + '_employee').show();
					}

					scope.assignEmployee = function (task, employee_id) {
						$log.info('assign employee called');
						return Tasks.assignTask(task, employee_id).then(
							function (result) {
								if (task.user_id) {
									toastr.success($translate.instant('GLOBAL.TASK_ASSIGNED'));
								} else {
									toastr.success($translate.instant('GLOBAL.TASK_UNASSIGNED'));
								}
							},
							function (reason) {
								if (reason && reason.status && reason.status === -1) {
									toastr.error('Error assigning driver');
								} else if (reason) {
									toastr.error(reason);
								}
							}
						);
					};

					scope.$on('columns updated', function (_, doneCallback) {
						scope._init().then(function () {
							$timeout(refreshData).then(function () {
								if (typeof doneCallback === 'function') {
									doneCallback();
								}
							});
						});
					});

					scope.throttledRefreshData = _.throttle(function () {
						refreshData();
					}, DEFAULT_RT_THROTTLE);

					scope.handleListUpdate = function () {
						scope.throttledRefreshData();
					};

					/**
					 * controller init function
					 */
					scope._init = function (options) {
						initGridColumns();
						AutoDispatchAnalytic.init();

						if (!scope.inited && !scope.pendingInit) {
							scope.$on('$translateChangeSuccess', function () {
								$log.log('TasksTable - translation changed, rebuilding columns');
								initGridColumns();
							});

							scope.$on('task list update', scope.handleListUpdate);
							scope.$on('lazy task list update', scope.handleListUpdate);
							$rootScope.$on('employees list update', function () {
								Employees.drivers().then(function (drivers) {
									scope.employees = drivers;
								});
							});

							subscriptions.push(
								ManagedAttributesService.listenForChanges(function () {
									scope.throttledRefreshData();
								})
							);
						}
						TasksLoader.loadOpenForTable(options);

						// To avoid possible multiple _init() calls while scope.inited still false
						// otherwise it seems to result in duplicated scope.$on subscriptions
						scope.pendingInit = true;

						return $q.all([Employees.drivers()]).then(function (values) {
							if (!attrs.tasks) {
								scope.tasks = TasksData.getOpenTasks();
							}

							scope.employees = values[0];
							scope.inited = true;
							scope.pendingInit = false;
						});
					};

					/**
					 * print task
					 */
					w.on('handleTaskGridPrintReceiptFilter', function (event, id) {
						var taskIds = [id];
						Tasks.batchGet(taskIds).then(function (tasks) {
							PrintTasksService.printTasks(tasks);
						});
					});

					w.on(
						'handleTaskGridPrintLabelFilter',
						_.debounce(
							function (event, id, type) {
								CrossApplicationService.emit(CROSS_APP_ACTIONS.PRINT_LABEL_REQUEST, {
									taskIds: [id],
									type
								});
							},
							300,
							{ leading: true }
						)
					);

					scope.onInit = function (grid, dataView) {
						dataView.setFilter(GridColumnsService.getFilterFuncForGrid(grid));
						scope.grid.ready = true;
						if (scope.onInited) {
							scope.onInited();
						}
					};

					scope.$on('config updated', function () {
						initGridColumns();
					});

					// comment to avoid any unexpected(opaque) UI flow when the PresetView is applied or data loaded the table is fully refreshed
					CrossApplicationService.on(CROSS_APP_ACTIONS.PRESET_VIEWS_UPDATED, initGridColumns);

					scope.inited = false;
					scope.pendingInit = false;
					// start init function
					scope._init({ columnsKey: scope.columnsKey });

					function refreshData() {
						if (scope.grid.ready) {
							const newDataFunc = Task.shouldUseNewApi() && TasksData.getOpenTasks;
							scope.grid.refreshData(newDataFunc, scope.groupingType);
						}
					}
				}
			};
		}
	);
